import SearchIcon from "@material-ui/icons/Search"
import Link from "next/link"
import classnames from "classnames"
import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { getQueryStringValue } from "../../../../services/utils/getQueryStringValue"
import styles from "./LogoAndSearch.module.scss"
import { useRouter } from "next/router"

export const LogoAndSearch = ({ setSearch }) => {
  const [displayIcon, setDisplayIcon] = useState(false)
  const [query, setQuery] = useState("")
  const [queryString, setQuerystring] = useState("")
  const router = useRouter()

  const displayQuery = query || queryString

  useEffect(() => {
    setTimeout(() => {
      setDisplayIcon(true)
    }, 200)

    const s = getQueryStringValue("search")
    if (!!s) {
      setQuerystring(s)
    }
    const clearSearchBar = () => {
      setQuerystring("")
      setQuerystring("")
    }
    router.events.on("routeChangeStart", clearSearchBar)
    return () => {
      router.events.off("routeChangeStart", clearSearchBar)
    }
  }, [])

  const onUpdateInput = () => {
    if (query !== "") setSearch(query)
  }

  return (
    <div id="logo" className="justify-content-center justify-content-lg-start">
      <Link href="/" className={styles["artplacer-logo"]}></Link>
      <div className="ml-4 w-100 position-relative d-none d-md-block">
        <Form.Control
          className={styles["search-input"]}
          type="text"
          placeholder="Search artworks, galleries, or virtual exhibitions"
          onChange={(e) => {
            setQuery(e.target.value), setQuerystring(e.target.value)
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onUpdateInput()
            }
          }}
          value={displayQuery}
        />
        <SearchIcon
          onClick={onUpdateInput}
          style={{
            fontSize: "24px",
            display: displayIcon ? "block" : "none",
          }}
          className={classnames(styles["search-icon"], "cursor-pointer")}
        />
      </div>
    </div>
  )
}
