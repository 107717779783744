import React from "react"
import classnames from "classnames"

const MobileMenu = ({ children, className }) => {
  return (
    <div className={classnames("menu-mobile-container", className)}>
      {children}
    </div>
  )
}

export default MobileMenu
