import React, { useContext, useState } from "react"
import { useSearchParams } from "next/navigation"

const appContext = React.createContext()

export function useAppContext() {
  return useContext(appContext)
}

export function AppProvider({ children }) {
  const searchParams = useSearchParams()
  const [profile, setProfile] = useState({
    embedded:
      searchParams.get("embedded") && searchParams.get("embedded") == "true",
  })

  return <appContext.Provider value={profile}>{children}</appContext.Provider>
}
