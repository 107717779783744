import Router from "next/router"
import NProgress from "nprogress"
import React from "react"
import Footer from "../features/components/Layout/Footer"
import Header from "../features/components/Layout/Header"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import "nprogress/nprogress.css"
import "../styles/globals.css"
import "../styles/style.css"
import "../styles/fonts.scss"
import "../styles/texts.scss"
import { AppProvider } from "../features/providers/AppProvider"
;(function () {
  Router.events.on("routeChangeStart", () => NProgress.start())
  Router.events.on("routeChangeComplete", () => NProgress.done())
  Router.events.on("routeChangeError", () => NProgress.done())
})()

const MyApp = ({ Component, pageProps }) => {
  return (
    <AppProvider>
      <Header />
      <Component {...pageProps} />
      <Footer />
    </AppProvider>
  )
}

export default MyApp
