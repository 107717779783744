class WindowService {
  window

  constructor() {
    this.window = process.browser ? window : 0
  }

  animatedScrollTop() {
    const clear = setInterval(() => {
      const scrollY = window.scrollY
      window.scrollTo(0, scrollY - 50)

      if (scrollY === 0) {
        clearInterval(clear)
      }
    }, 10)
  }
}

export default new WindowService()
