export const getQueryStringValue = (key) => {
  try {
    const query = window.location.search.substring(1)
    const vars = query.split("&")
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=")
      if (decodeURIComponent(pair[0]) === key) {
        return decodeURIComponent(pair[1])
      }
    }

    return ""
  } catch (_e) {
    return ""
  }
}
