import Link from "next/link"
import classnames from "classnames"
import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { links } from "../Header/links"
import { SocialIcons } from "../SocialIcons"
import styles from "./logoFooter.module.css"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import WindowService from "../../../../services/WindowService"

const Footer = () => {
  const [displayTop, setDisplayTop] = useState(false)
  const trackScrolling = () => {
    setDisplayTop(window.scrollY > 350)
  }

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling)

    return () => {
      document.removeEventListener("scroll", trackScrolling)
    }
  })

  const handleClick = () => {
    WindowService.animatedScrollTop()
  }

  return (
    <footer
      id="footer"
      className={classNames(
        "white",
        styles["footer-border"],
        styles["background-white"],
        "position-relative",
      )}>
      <div
        onClick={handleClick}
        className={classnames(styles["icon"], {
          active: displayTop,
        })}>
        <ExpandLessIcon />
      </div>
      <div id="copyrights" className={styles["background-white"]}>
        <div className="container">
          <div className="w-100 text-center">
            <div className="copyrights-menu copyright-links clearfix">
              <div>
                <Link target={`_blank`} href={`https://www.artplacer.com`}>
                  <div className={styles["footer-logo"]} />
                </Link>
              </div>
            </div>
            <SocialIcons />

            <p className="mt-2 pt-1 fs-13px color-gray-super-light font-primary">
              All rights reserved &copy;
              <a
                className="color-gray-super-light"
                href="https://www.artplacer.com"
                target="_blank">
                ArtPlacer
              </a>{" "}
              {new Date().getFullYear().toString()}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
