import CopyrightIcon from "@material-ui/icons/Copyright"
import React from "react"
import Link from "next/link"
import { useHeaderActions } from "../../../actions/useHeaderActions"
import { SocialIcons } from "../SocialIcons"
import styles from "./Header.module.css"
import { links } from "./links"

import MobileMenu from "../MobileMenu"
import classnames from "classnames"
import { LogoAndSearch } from "./LogoAndSearch"
import { SearchMobile } from "./SearchMobile"
import { useAppContext } from "../../../providers/AppProvider"

const Header = () => {
  const profile = useAppContext()
  const { setSearch, mobileMenu, handleClose, handleShow, pathEq } =
    useHeaderActions()

  return (
    !profile.embedded && (
      <header
        id="header"
        className={classnames("full-header header-size-custom", styles.header)}
        data-sticky-shrink="false">
        <div id="header-wrap" className={styles["header-border"]}>
          <div className="container">
            <div className="header-row row ">
              <div className="col col-xs-6 col-sm-8 col-md-8 col-xl-6 search">
                <LogoAndSearch setSearch={setSearch} />
              </div>

              <div className="col col-xs-6 col-sm-4 col-md-4 col-xl-6">
                <div className="d-flex align-items-center">
                  {mobileMenu ? (
                    <div
                      className="close-mobile-menu d-block mobileMenuCss font-weight-bold"
                      style={{ margin: "0 0 0 auto" }}
                      onClick={handleClose}>
                      <a href="javascript:void(0)" className="mb-0">
                        x
                      </a>
                    </div>
                  ) : (
                    <div
                      className="d-block mobileMenuCss"
                      style={{ margin: "0 0 0 auto" }}
                      onClick={handleShow}>
                      <svg className="svg-trigger" viewBox="0 0 100 100">
                        <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                        <path d="m 30,50 h 40" />
                        <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                      </svg>
                    </div>
                  )}
                </div>
                <nav className="primary-menu float-right">
                  <ul className="menu-container border-right-0 mr-0">
                    {links.map((link) => (
                      <li
                        key={link.path}
                        className={classnames(
                          "menu-item",
                          styles["menu-item"],
                        )}>
                        <Link href={link.path} className="menu-link">
                          <div>
                            {link.title}
                            <div
                              className={classnames(styles["border-item"], {
                                "non-border-bottom": !pathEq(link.path),
                                "h-3": !pathEq(link.path),
                              })}
                            />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="mb-2">
              <SearchMobile setSearch={setSearch} />
            </div>
          </div>
        </div>
        <div className="header-wrap-clone" style={{ height: "70px" }} />
        {mobileMenu && (
          <MobileMenu>
            <nav className="mt-2">
              <ul className=" border-right-0 mr-0">
                {links.map((link) => (
                  <li
                    key={link.path}
                    className={classnames("menu-item", styles["menu-item"])}>
                    <Link
                      href={link.path}
                      className="menu-link"
                      onClick={handleClose}>
                      <div>
                        {link.title}
                        <div
                          className={classnames(styles["border-item"], {
                            "non-border-bottom": !pathEq(link.path),
                            "h-3": !pathEq(link.path),
                          })}
                        />
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="text-center">
              <SocialIcons />

              <p className="mt-4 fs-13px color-gray-super-light font-primary">
                All rights reserved <CopyrightIcon style={{ fontSize: 15 }} />{" "}
                <a
                  className="color-gray-super-light"
                  href="https://www.artplacer.com"
                  target="_blank">
                  ArtPlacer
                </a>{" "}
                {new Date().getFullYear().toString()}
              </p>
            </div>
          </MobileMenu>
        )}
      </header>
    )
  )
}

export default Header
