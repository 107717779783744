import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import React from "react"

export const SocialIcons = () => (
  <>
    <a
      href="https://www.facebook.com/artplacer"
      className="mr-3"
      target="_blank">
      <FacebookIcon style={{ width: 18, fontSize: 18 }} />
    </a>

    <a href="https://www.instagram.com/artplacer.app/" target="_blank">
      <InstagramIcon style={{ width: 18, fontSize: 18 }} />
    </a>
  </>
)
