import { useRouter } from "next/router"
import { useState, useEffect } from "react"
import { getQueryStringValue } from "../../services/utils/getQueryStringValue"
import { useNavigateTo } from "../../services/utils/useNavigateTo"

export const useHeaderActions = () => {
  const [search, setSearch] = useState(null)
  const navigateTo = useNavigateTo()
  const router = useRouter()
  const [mobileMenu, openMenu] = useState(false)
  const handleShow = () => openMenu(true)
  const handleClose = () => openMenu(false)
  const pathEq = (path) => {
    if (path === "/") {
      return path === router.pathname
    }
    return router.pathname.startsWith(path)
  }

  useEffect(() => {
    if (search !== null) {
      window.location.href = `/search?search=${search}`
    }
  }, [search])

  return {
    search,
    setSearch,
    mobileMenu,
    handleShow,
    handleClose,
    pathEq,
  }
}
